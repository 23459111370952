.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: fit-content;
  margin: 0 auto;
  background-color: #f5f5f500;

}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000000 90%, #00000000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}

.cricket-ball-loader {
  overflow: hidden;
  width: fit-content;
  margin: 10rem auto;
}

.spinner {
  width: auto;
  height: 7.5rem;
  animation: spin 1s infinite linear;
  transform-origin: center center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 799px) {
  .cricket-ball-loader {
    margin: 12rem auto;
  }

  .spinner {
    height: 7rem;
  }
}